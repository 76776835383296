import InputMask from 'react-input-mask';
import Input from 'src/components/Input';

const PhoneInput = (props) => {
  const { input } = props;
  return (
    <InputMask
      disabled={false}
      mask="+7 (999) 999-99-99"
      {...input}
    >
      {(InputProps) => <Input disabled={false} {...InputProps} {...props} />}
    </InputMask>
  );
};

export default PhoneInput;
