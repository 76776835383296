import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import {
  Box, Typography,
} from '@mui/material';

import CustomButton from 'src/components/Button';
import Input from 'src/components/Input';
import PhoneInput from 'src/components/PhoneInput';
import Meta from 'src/components/Meta';
import { useAuth } from 'src/contexts/auth';

import { actions as snackbar } from 'src/store/snackbar/slice';
import { composeValidators, validatePhoneNumber, required } from 'src/lib/validation';
import api from 'src/lib/api';
import getResponseErrorMessage from 'src/lib/utils';
import { PageProps } from 'src/interfaces';
import { StyledAuthorizationPaper } from 'src/components/Layout';

const Login = ({ isMobile }: PageProps) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { authData, updateAuthData } = useAuth();
  const onSubmit = (formData) => {
    const data = { ...formData, phone: formData.phone.replace(/\D/g, '') };
    api.post('users/login/', { data })
      .then((response) => {
        if (!response.throwError) {
          updateAuthData({ ...authData, user: { ...response }, is_authenticated: true });
          router.push({
            pathname: '/',
          });
        }
      })
      .catch((error) => {
        dispatch(snackbar.openErrorSnackbar(getResponseErrorMessage(error)));
      });
  };

  return (
    <Box sx={{
      display: 'flex',
      width: '100%',
      paddingRight: isMobile ? '0px' : '240px',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <Meta title="Войти" />
      <StyledAuthorizationPaper>
        <Typography sx={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: 32,
          fontWeight: 900,
          marginBottom: '40px',
        }}
        >
          Utrade
        </Typography>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, errors }) => (
            <form onSubmit={handleSubmit}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
              >
                <Field
                  name="phone"
                  component={PhoneInput}
                  variant="standard"
                  label="Телефон"
                  sx={{ marginBottom: '24px' }}
                  validate={composeValidators(validatePhoneNumber, required)}
                />
                <Field
                  name="password"
                  component={Input}
                  variant="standard"
                  label="Пароль"
                  placeholder="Введите пароль"
                  type="password"
                  sx={{ mb: '40px' }}
                  validate={required}
                />
                <CustomButton title="Войти" type="submit" disabled={!isEmpty(errors)} />
              </Box>
            </form>
          )}
        />
      </StyledAuthorizationPaper>
    </Box>
  );
};

export default Login;
